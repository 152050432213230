@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?hv16kf');
  src:  url('../fonts/icomoon.eot?hv16kf#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?hv16kf') format('truetype'),
    url('../fonts/icomoon.woff?hv16kf') format('woff'),
    url('../fonts/icomoon.svg?hv16kf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-angle-right:before {
  content: "\e90f";
}
.icon-angle-left:before {
  content: "\e900";
}
.icon-cheveron-right:before {
  content: "\e90c";
}
.icon-cheveron-left:before {
  content: "\e901";
}
.icon-phone2:before {
  content: "\e902";
}
.icon-phone-call:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e905";
}
.icon-mail-envelope-closed:before {
  content: "\e906";
}
.icon-mail-envelope-closed1:before {
  content: "\e907";
}
.icon-vk1:before {
  content: "\e908";
}
.icon-location1:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e90a";
}
.icon-arrow-left:before {
  content: "\e90b";
}
.icon-envelope:before {
  content: "\e90d";
}
.icon-paper-plane:before {
  content: "\e90e";
}
.icon-instagram1:before {
  content: "\e913";
}
.icon-menu:before {
  content: "\e915";
}
.icon-whatsapp:before {
  content: "\e916";
}
.icon-close:before {
  content: "\e918";
}
.icon-phone:before {
  content: "\e942";
}
.icon-phone1:before {
  content: "\e943";
}
.icon-location:before {
  content: "\e947";
}
.icon-star-full:before {
  content: "\e9d9";
}